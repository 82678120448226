<template>
  <div id="navigation">
    <v-navigation-drawer
      v-model="drawer"
      :color="this.$vuetify.theme.themes.light.secondary"
      app
    >
      <template v-slot:prepend>
        <v-list-item two-line dark>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ userName }}</v-list-item-title>
            <v-list-item-subtitle
              ><v-btn
                plain
                x-small
                class="pa-0"
                @click="$router.push({ path: '/usuarios/perfil' })"
                >Perfil</v-btn
              ></v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn @click="logout()" icon>
              <v-icon color="grey lighten-1">mdi-logout-variant</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <Menu />
    </v-navigation-drawer>
    <v-app-bar app :color="this.$vuetify.theme.themes.light.primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-row>
          <v-col cols="3"><v-img max-width="180" :src="srcLogo"></v-img></v-col>
          <v-col cols="9">Central do anunciante</v-col>
        </v-row>
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
import Menu from "@/components/template/Menu";
import errorHandler from "@/helpers/error_handler";
import logo from "../../assets/logo-preto.png";
import { mapActions, mapState } from "vuex";

export default {
  components: { Menu },
  data: () => ({ drawer: null, srcLogo: logo }),
  methods: {
    ...mapActions("user", ["signout"]),
    async logout() {
      try {
        this.signout();

        this.$router.push("/login");
      } catch (error) {
       console.error(error);

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      }
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    userName() {
      if (!this.userData) return null;
      else if (this.userData.type === "legal-person")
        return this.userData.fantasyName;
      else if (this.userData.type === "physical-person")
        return `${this.userData.firstName} ${this.userData.lastName}`;
      else return null;
    },
  },
};
</script>

<style></style>
