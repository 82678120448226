import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import adverts from "./modules/adverts";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { user, adverts },
  strict: true,
});

export default store;
