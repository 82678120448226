const state = {
  userData: null,
};

const mutations = {
  setUser(state, user) {
    state.userData = user;
  },
  setCompanies(state, companies) {
    state.companies = companies;
  },
  clearUser(state) {
    state.userData = null;
  },
};

const actions = {
  async vSignin({ commit }, accessData) {
    const url = "customers/signin";

    const response = await this._vm.$axios.post(url, accessData);

    commit("setUser", response.data.userData);
  },
  async signout({ commit }) {
    await this._vm.$axios.post("customers/signout");

    commit("setUser", null);
  },
  async vSignup({ commit }, userData) {
    const response = await this._vm.$axios.post("/customers/signup", userData);

    const data = { ...response.data };

    commit("setUser", data.userData);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
