export default [
  {
    path: "/estatisticas",
    name: "Statistics",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Statistics/Statistics.vue"
      ),
  },
];
