<template>
  <v-list>
    <v-list-item
      v-for="item in items"
      :key="item.title"
      dark
      :to="{ path: item.route }"
      link
    >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Meus anúncios",
        icon: "mdi-monitor",
        route: "/anuncios",
      },
      {
        title: "Estatísticas",
        icon: "mdi-chart-box",
        route: "/estatisticas",
      },
      {
        title: "Financeiro",
        icon: "mdi-cash-multiple",
        route: "/cobrancas",
      },
      { title: "Minha empresa", icon: "mdi-domain", route: "/empresa" },
    ],
  }),
};
</script>

<style scoped>
.v-list .v-list-item--active {
  background-color: var(--v-primary-base);
  color: #000;
}
</style>
