export default [
  {
    path: "/anuncios",
    name: "Adverts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Announcement/Adverts.vue"),
  },
  {
    path: "/anuncio/:id/completar-cadastro",
    name: "CompleteRegistrationOfAd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Announcement/CompleteRegistration.vue"),
  },
  {
    path: "/anuncio/:id/editar",
    name: "AnnouncementUpdate",
    props: { mod: "update", title: "Editar anúncio" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Announcement/AnnouncementForm.vue"),
  },
  {
    path: "/anuncio/:id/configurar",
    name: "CompanySettings",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Announcement/AdSettings.vue"),
  },
]