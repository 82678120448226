<template>
  <v-app id="inspire">
    <Navigation
      v-if="!['Login', 'UserRegistration'].includes($route.name) && userData"
    />

    <v-main>
      <div class="text-center" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <Content v-else />
    </v-main>

    <ErrorDialog ref="errorDialog"></ErrorDialog>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-app>
</template>

<script>
import Navigation from "@/components/template/Navigation";
import Content from "@/components/template/Content";
import ErrorDialog from "@/components/template/ErrorDialog";
import ConfirmDialog from "@/components/template/ConfirmDialog";
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import { baseUrl } from "@/config/config.json";

export default {
  components: { Navigation, Content, ErrorDialog, ConfirmDialog },
  computed: {
    ...mapState("user", ["userData"]),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapMutations("user", ["setUser"]),
    async checkToken() {
      try {
        this.loading = true;

        const axiosInstance = axios.create({
          baseURL: baseUrl,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const response = await axiosInstance.post("users/check-token");

        const data = { ...response.data };

        this.setUser(data);
      } catch (error) {
        this.$router.push("/login");
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$root.$errorDialog = this.$refs.errorDialog.open;
    this.$root.$confirm = this.$refs.confirmDialog.open;
  },
  created() {
    this.checkToken();
  },
};
</script>

<style>
.required label::after {
  content: " *";
  color: red;
}
.swal2-popup {
  font-family: "Roboto", sans-serif !important;
}
</style>
