export default [
  {
    path: "/empresas",
    name: "Companies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Company/Companies.vue"),
  },
  {
    path: "/empresas/registrar",
    name: "CompanyRegistration",
    props: { mod: "insert", title: "Cadastrar empresa" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Company/CompanyForm.vue"
      ),
  },
  {
    path: "/empresa/:companyId/editar",
    name: "CompanyUpdate",
    props: { mod: "update", title: "Editar empresa" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Company/CompanyForm.vue"
      ),
  },
  {
    path: "/empresa",
    name: "MyCompany",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Company/MyCompany.vue"),
  },
];
