export default [
  {
    path: "/usuarios/perfil",
    name: "UserProfile",
    props: { mod: "insert", title: "Perfil" },
    component: () =>
      import(
        /* webpackChunkName: "UserProfile" */ "../views/Users/Profile.vue"
      ),
  },
  {
    path: "/usuarios/cadastro",
    name: "UserRegistration",
    props: { mod: "insert", title: "Cadastro" },
    component: () =>
      import(
        /* webpackChunkName: "UserRegistration" */ "../views/Users/Register.vue"
      ),
  },
];
