import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import moment from "./plugins/moment";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;

const sweetalert2options = {
  confirmButtonColor: "#FEA520",
  cancelButtonColor: "#333",
};

Vue.use(axios);
Vue.use(moment);
Vue.use(VueSweetalert2, sweetalert2options);
Vue.use(Toast);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
