export default [
  {
    path: "/cobrancas",
    name: "Charges",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Charges/Charges.vue"),
  },
  {
    path: "/cobrancas/:chargeId/pagar",
    name: "PayCharge",
    component: () =>
      import(
        /* webpackChunkName: "PayCharge" */ "../views/Charges/PayCharge.vue"
      ),
  },
];
